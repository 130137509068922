<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-list"></i>
          استعراض الباصات
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>العنوان</th>
              <th>خط السير</th>
              <th>التفاصيل</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="bus in busses" :key="bus._id">
                <td>
                  {{ bus.title }}
                </td>
                <td v-html="groupName(bus.group_id)"></td>
                <td>
                  {{ bus.details }}
                </td>
                <td>
                  <b-link
                    :to="'/bus/edit/' + bus._id"
                    class="btn btn-relief-success btn-sm"
                  >
                    <i class="fa fa-edit"></i>
                    تعديل
                  </b-link>
                  &nbsp;
                  <button
                    @click="deleteBus(bus._id)"
                    class="btn btn-relief-danger btn-sm"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
  },
  created() {
    checkPer(this, "bus", "view");
    var g = this;
    $.post(api + "/admin/bus/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.busses = JSON.parse(r).response;
    });
    $.post(api + "/admin/groups/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.groups = JSON.parse(r).response;
    });
  },
  data() {
    return {
      busses: [],
      groups: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    groupName(id) {
      var title = `<span class='badge bg-danger'>لا يوجد</span>`;
      this.groups.forEach(function (e) {
        if (e._id == id) {
          title = e.title;
        }
      });
      return title;
    },
    deleteBus(id) {
      var g = this;
      if (confirm("متأكد من حذف الباص؟") && checkPer(g, "bus", "delete")) {
        $.post(api + "/admin/bus/delete", {
          jwt: g.user.jwt,
          id: id,
        }).then(function (r) {
          alert("تم الحذف");
          $.post(api + "/admin/bus/list", {
            jwt: g.user.jwt,
          }).then(function (r) {
            g.busses = JSON.parse(r).response;
          });
        });
      }
    },
  },
};
</script>

<style>
</style>